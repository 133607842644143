<template>
    <el-main>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="创建活动" name="1">
                <el-form class="vote" ref="form" :model="voteAddForm" :rules="voteRules" label-width="120px">
                    <el-form-item label="投票名称：" prop="vote_name">
                        <el-input v-model="voteAddForm.vote_name"></el-input>
                    </el-form-item>
                    <el-form-item label="列表图片：" prop="cover_picture">
                        <ReadyUploadSource @getSource="val => (voteAddForm.cover_picture = val.path)"
                            :path="voteAddForm.cover_picture" @removeThis="() => (voteAddForm.cover_picture = '')">
                        </ReadyUploadSource>
                    </el-form-item>
                    <el-form-item label="投票时间：">
                        <div class="date">
                            <el-form-item prop="vote_start_time">
                                <el-date-picker v-model="voteAddForm.vote_start_time" type="datetime"
                                    value-format="timestamp" placeholder="投票开始时间"></el-date-picker>
                            </el-form-item>
                            <span style="margin: 0 5px">~</span>
                            <el-form-item prop="vote_end_time">
                                <el-date-picker v-model="voteAddForm.vote_end_time" type="datetime" value-format="timestamp"
                                    placeholder="投票结束时间"></el-date-picker>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="投票banner：" prop="banner_picture">
                        <ReadyUploadSource @getSource="getServiceImg" @changeOrder="changeOrder"
                            @removeThis="removeImageThis" :isMany="true" :manyPath="voteAddForm.banner_picture"
                            :maxSize="1024 * 1024"></ReadyUploadSource>
                    </el-form-item>
                    <el-form-item label="投票限制：">
                        <el-radio-group v-model="voteAddForm.is_restriction">
                            <el-radio :label="0">无限制</el-radio>
                            <el-radio :label="1">自定义</el-radio>
                        </el-radio-group>
                        <div class="vote-rule" v-if="voteAddForm.is_restriction">
                            <el-form-item label="投票频率" label-width="80px" prop="frequency">
                                <el-select v-model="voteAddForm.frequency" placeholder="总计/每周/每天">
                                    <el-option label="总计" :value="0"></el-option>
                                    <el-option label="每周" :value="1"></el-option>
                                    <el-option label="每天" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="投票次数" label-width="80px" prop="vote_sum">
                                <el-input style="width: 240px" v-model="voteAddForm.vote_sum">
                                    <template slot="append">次</template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="投票规则：" prop="rule">
                        <RichText width="700" :richTxt="voteAddForm.rule" @soninfo="val => (voteAddForm.rule = val)">
                        </RichText>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="添加被投票人" name="2">
                <div class="voter">
                    <div class="item" v-for="(item, index) in voterList" :key="index">
                        <div class="userInfo">
                            <el-image :src="item.avatar"></el-image>
                            <div class="name">
                                <div>{{ item.name }}</div>
                                <div>{{ item.serial_number }}</div>
                            </div>
                        </div>
                        <div class="operation">
                            <el-button type="text" @click="editVoter(item)">编辑</el-button>
                            <el-button type="text" @click="delVoter(item.id, index)">删除</el-button>
                        </div>
                    </div>
                    <div class="addVoter" @click="addVoter">
                        <i class="el-icon-plus"></i>
                        添加被投票人
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="添加被投票人" :visible.sync="showAddvoter" width="650px">
            <el-form ref="form1" :model="votePersonAddForm" :rules="votePersonRules" label-width="120px">
                <el-form-item label="被投票人姓名：" prop="name">
                    <el-input v-model="votePersonAddForm.name"></el-input>
                </el-form-item>
                <el-form-item label="编号：" prop="serial_number">
                    <el-input v-model="votePersonAddForm.serial_number"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input v-model="votePersonAddForm.sort" type="number"></el-input>
                </el-form-item>
                <el-form-item label="头像：" prop="avatar">
                    <ReadyUploadSource @getSource="val => (votePersonAddForm.avatar = val.path)"
                        :path="votePersonAddForm.avatar" @removeThis="() => (votePersonAddForm.avatar = '')">
                    </ReadyUploadSource>
                </el-form-item>
                <el-form-item label="简介：" prop="introduction">
                    <el-input v-model="votePersonAddForm.introduction" type="textarea" :rows="8"
                        :autosize="{ minRows: 8, maxRows: 1000 }" placeholder="请输入内容"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAddvoter = !1">取 消</el-button>
                <el-button type="primary" @click="submit(0)">确 定</el-button>
            </span>
        </el-dialog>
        <Preservation @preservation="submit(1)" :text="activeName == 1 ? '下一步' : '保存'">
            <router-link to="/extension/vote/voteList" slot="return" v-if="id" style="margin-right: 10px">
                <el-button>返回</el-button>
            </router-link>
        </Preservation>
    </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
import _ from 'lodash';
export default {
    components: {
        ReadyUploadSource,
        Preservation,
        RichText,
    },
    data () {
        return {
            id: '',
            vote_id: '',
            activeName: '1',
            showAddvoter: !1,
            voteAddForm: {
                vote_name: '',
                cover_picture: '',
                banner_picture: [],
                vote_start_time: '',
                vote_end_time: '',
                rule: '',
                is_restriction: 0,
                frequency: '',
                vote_sum: '',
            },
            voterList: [],
            votePersonAddForm: {
                vote_id: '',
                serial_number: '',
                name: '',
                avatar: '',
                introduction: '',
                sort: '',
            },
            voteRules: {
                vote_name: {
                    required: true,
                    message: '请填写投票名称',
                    trigger: 'blur',
                },
                cover_picture: {
                    required: true,
                    message: '请添加列表图片',
                    trigger: 'blur',
                },
                vote_start_time: {
                    required: true,
                    message: '请设置投票开始时间',
                    trigger: 'blur',
                },
                vote_end_time: {
                    required: true,
                    message: '请设置投票结束时间',
                    trigger: 'blur',
                },
                banner_picture: {
                    required: true,
                    message: '请添加banner图片',
                    trigger: 'blur',
                },
                frequency: {
                    required: true,
                    message: '请选择投票频率',
                    trigger: 'blur',
                },
                vote_sum: {
                    required: true,
                    message: '请填写投票次数',
                    trigger: 'blur',
                },
                rule: {
                    required: true,
                    message: '请填写投票规则',
                    trigger: 'blur',
                },
            },
            votePersonRules: {
                name: {
                    required: true,
                    message: '请填写被投票人姓名',
                    trigger: 'blur',
                },
                serial_number: {
                    required: true,
                    message: '请填写编号',
                    trigger: 'blur',
                },
                sort: {
                    required: true,
                    message: '请填写排序',
                    trigger: 'blur',
                },
                avatar: {
                    required: true,
                    message: '请添加头像',
                    trigger: 'blur',
                },
                introduction: {
                    required: true,
                    message: '请填写简介',
                    trigger: 'blur',
                },
            },
        };
    },
    created () {
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getInfo();
            this.getVoterList();
        }
    },
    methods: {
        //获取banner图片
        getServiceImg (val) {
            let arr = [...this.voteAddForm.banner_picture];
            for (let i in val) arr.push(val[i].path);
            this.voteAddForm.banner_picture = arr;
        },
        // 拖动重新赋值数据
        changeOrder (val) {
            this.voteAddForm.banner_picture = val;
        },
        // 删除单张图片
        removeImageThis (index) {
            this.voteAddForm.banner_picture.splice(index, 1);
        },
        getInfo () {
            this.$axios
                .post(this.$api.sports.voteInfo, {
                    id: this.id,
                })
                .then(res => {
                    if (res.code == 0) {
                        let info = res.result;
                        let voteAddForm = this.voteAddForm;
                        voteAddForm.id = info.id;
                        voteAddForm.vote_name = info.vote_name;
                        voteAddForm.cover_picture = info.cover_picture;
                        voteAddForm.banner_picture = JSON.parse(info.banner_picture);
                        voteAddForm.vote_start_time = info.vote_start_time * 1000;
                        voteAddForm.vote_end_time = info.vote_end_time * 1000;
                        voteAddForm.rule = info.rule;
                        voteAddForm.is_restriction = info.is_restriction;
                        if (info.is_restriction) {
                            voteAddForm.frequency = info.frequency;
                            voteAddForm.vote_sum = info.vote_sum; ``
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        submit (type) {
            if (this.activeName == '1') {
                if (this.vote_id) {
                    this.activeName = '2';
                } else {
                    this.$refs.form.validate(valid => {
                        if (valid) {
                            let voteAddForm = _.cloneDeep(this.voteAddForm);
                            voteAddForm.vote_start_time = voteAddForm.vote_start_time / 1000;
                            voteAddForm.vote_end_time = voteAddForm.vote_end_time / 1000;
                            if (!voteAddForm.is_restriction) {
                                delete voteAddForm.frequency;
                                delete voteAddForm.vote_sum;
                            }
                            this.$axios.post(this.id ? this.$api.sports.editVote : this.$api.sports.addVote, voteAddForm).then(res => {
                                if (res.code == 0) {
                                    this.activeName = '2';
                                    if (!this.id) {
                                        this.votePersonAddForm.vote_id = res.result;
                                        this.vote_id = res.result;
                                    }
                                    this.$message.success(this.id ? '编辑成功' : '创建成功');
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                        }
                    });
                }
            } else {
                if (type) {
                    if (this.id) {
                        this.$router.push('/extension/vote/voteList');
                    } else {
                        if (!this.vote_id) return this.$message.warning('请先创建活动');
                        if (!this.voterList.length) return this.$message.warning('暂未添加被投票人');
                        this.$router.push('/extension/vote/voteList');
                    }
                } else {
                    this.$refs.form1.validate(valid => {
                        if (valid) {
                            let votePersonAddForm = _.cloneDeep(this.votePersonAddForm);
                            if (this.id) votePersonAddForm.vote_id = this.id;
                            this.$axios.post(votePersonAddForm.id ? this.$api.sports.votePersonEdit : this.$api.sports.votePersonAdd, votePersonAddForm).then(res => {
                                if (res.code == 0) {
                                    this.$message.success(this.id ? '编辑成功' : '保存成功');
                                    this.showAddvoter = !1;
                                    this.getVoterList();
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                        }
                    });
                }
            }
        },
        getVoterList () {
            this.$axios
                .post(this.$api.sports.votePersonList, {
                    vote_id: this.vote_id || this.id,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.voterList = res.result;
                    }
                });
        },
        addVoter () {
            if (this.vote_id || this.id) {
                this.showAddvoter = !0;
                delete this.votePersonAddForm.id;
            } else {
                this.$message.warning('请先创建活动');
            }
        },
        editVoter (row) {
            this.showAddvoter = !0;
            this.votePersonAddForm.id = row.id;
            this.votePersonAddForm.serial_number = row.serial_number;
            this.votePersonAddForm.name = row.name;
            this.votePersonAddForm.avatar = row.avatar;
            this.votePersonAddForm.introduction = row.introduction;
            this.votePersonAddForm.sort = row.sort;
        },
        delVoter (id, index) {
            this.$confirm('请确认是否删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.sports.votePersonDel, {
                        id: id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.voterList.splice(index, 1);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    padding-bottom: 70px;

    .vote {

        .el-input,
        .el-textarea {
            width: 600px;
        }

        .el-date-editor {
            width: 290px;
        }

        .date {
            display: flex;
        }

        .vote-rule {
            display: flex;
        }
    }

    .voter {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 300px;
            height: 150px;
            border: 1px solid #dcdfe6;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            margin-bottom: 20px;

            .userInfo {
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: 20px;

                .el-image {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .name div:nth-child(1) {
                    margin-bottom: 5px;
                }
            }

            .operation {
                background: #f8f8fa;
                border-radius: 0 0 10px 10px;
                padding: 0 30px;
                height: 50px;
                display: flex;
                justify-content: space-between;
            }
        }

        .addVoter {
            width: 300px;
            height: 150px;
            border: 1px solid #dcdfe6;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}</style>